import "../styles/Info.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import parse from "html-react-parser";
import { Trans } from "react-i18next";

import campovoloMap from "../assets/LIGABUE_CAMPOVOLO_2025_viale_mappa_APP.pdf";

function Campovolo(props) {
  const { t } = props;

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="info-mirror">
              <div className="info-part">
                <div className="info-head">{t("info.campovolo.head")}</div>
                <div className="info-title">{t("info.campovolo.bus.title")}</div>
                <div className="info-text">{parse(t("info.campovolo.bus.text"))}</div>
                <div className="info-title">{t("info.campovolo.car.title")}</div>
                <div className="info-text">{parse(t("info.campovolo.car.text"))}</div>
                <div className="info-title">{t("info.campovolo.train.title")}</div>
                <div className="info-text">{parse(t("info.campovolo.train.text"))}</div>
              </div>
              <div className="info-part">
                <div className="info-head">{t("info.campovolo.head.todo")}</div>
                <div className="info-title">{t("info.campovolo.map.title")}</div>
                {/* <div className="info-text">{parse(t("info.campovolo.map.text"))}</div> */}
                <div className="info-text"><Trans
                  i18nKey="info.campovolo.map.text"
                  components={{
                    a: <a href={campovoloMap} target="_blank" rel="noopener noreferrer" />,
                  }}
                /></div>
                <div className="info-title">{t("info.campovolo.tent.title")}</div>
                <div className="info-text">{parse(t("info.campovolo.tent.text"))}</div>
                <div className="info-title">{t("info.campovolo.merch.title")}</div>
                <div className="info-text">{parse(t("info.campovolo.merch.text"))}</div>
                {/* <div className="info-title">{t("info.campovolo.disclaimer.title")}</div>
                <div className="info-text">{parse(t("info.campovolo.disclaimer.text"))}</div> */}
                <div className="info-text-note">{parse(t("info.campovolo.note"))}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Campovolo;
