import "../styles/Login.scss";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import useFormValidation from "../hooks/useFormValidation";
import { useUser } from "../context/userContext";
import { forgotPassword, verifyOtpAndLogin } from "../utils/api";

function ForgotPassword(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [isSuccess, setIsSuccess] = useState(false);
  const [ajaxError, setAjaxError] = useState(null);
  const [user, setUser] = useState();

  const { t } = props;

  const navigate = useNavigate();
  const { updateUser } = useUser();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const {
    values = {},
    errors = {},
    handleFieldChange,
    validateForm,
    setHasSubmitted,
  } = useFormValidation(
    {
      email: "",
    },
    t
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHasSubmitted(true);
    const isValid = validateForm();
    if (!isValid) return;

    setLoading(true);
    try {
      const data = await forgotPassword({
        email: values.email,
      });

      setIsSuccess(true);
      setAjaxError(null);
    } catch (error) {
      setAjaxError(t("formErrors.error.otpGeneric"));
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (!isValid) return;

    setLoading(true);
    try {
      const loginData = await verifyOtpAndLogin(
        {
          email: values.email,
          token: values.otp,
          newPassword: values.password,
        },
        updateUser,
        true
      );
      props.setLoginSuccess(true);
      navigate("/welcome");
    } catch (error) {
      setAjaxError(t("formErrors.error.otpGeneric"));
      setIsSuccess(false);
      window.location.replace(window.location.href);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="page-title">{ isSuccess ? t("forgot.password.otp") : t("forgot.password")}</h1>
        </Col>
      </Row>
      {isSuccess && <p className="page-subtitle">{t("messages.msg.otp")}</p>}
      {isSuccess ? (
        <Form
          className="otpForm"
          noValidate
          validated={validated}
          onSubmit={handleNewPasswordSubmit}
        >
          <Form.Group controlId="otp" className="mb-3">
            <Form.Control
              type="text"
              name="otp"
              placeholder={t("formLabels.label.otp")}
              value={values.otp || ""}
              onChange={(e) => handleFieldChange("otp", e.target.value)}
              required
              isInvalid={!!errors.otp}
            />
            <Form.Control.Feedback type="invalid">
              {errors.otp}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password" className="mb-3 password-field">
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder={t("formLabels.label.password")}
              value={values.password}
              onChange={(e) => handleFieldChange("password", e.target.value)}
              required
              isInvalid={!!errors.password}
            />
            <Button
              variant="outline-secondary"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <i className="bi-eye" />
              ) : (
                <i className="bi-eye-slash" />
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col>
              <Button type="submit" disabled={loading}>
                {loading ? t("forms.loading") : t("forms.submit")}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Form.Group controlId="email">
              <Form.Control
                type="email"
                name="email"
                placeholder={t("formLabels.label.email")}
                value={values.email || ""}
                onChange={(e) => handleFieldChange("email", e.target.value)}
                required
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Button type="submit">{t("forms.submit")}</Button>
            </Col>
          </Row>
        </Form>
      )}
      {ajaxError && <p className="text-danger page-subtitle">{ajaxError}</p>}
    </Container>
  );
}

export default ForgotPassword;
