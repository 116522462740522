import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, InputGroup, FloatingLabel} from "react-bootstrap";
import { Trans } from "react-i18next";
import "../styles/Account.scss";
import { useNavigate } from "react-router-dom";
import { useGame } from "../context/gameContext";
import useFormValidation from "../hooks/useFormValidation";
import { deleteGame, deleteWearable, userEdit, getUserDetails, deleteUser, getGameStatus } from "../utils/api";
import { useUser, updateUser } from "../context/userContext";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";

const Account = (props) => {
  const [validated, setValidated] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [ajaxError, setAjaxError] = useState(null);
  const { t } = props;
  const { updateUser } = useUser();
  const { user } = useUser();
  const { updateGames, updateGamePrizes } = useGame();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const {
    values = {},
    errors = {},
    handleFieldChange,
    validateForm,
    setHasSubmitted,
    setFields,
  } = useFormValidation(
    {
      firstName: "",
      lastName: "",
      nickname: "",
      igUsername: "",
      phoneNumber: "",
      firstPartyMarketingConsent: "",
      thirdPartyMarketingConsent: ""
    },
    t
  );

  useEffect(() => {
    if (user) {
      setFields({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        nickname: user.nickname || "",
        igUsername: user.igUsername || "",
        phoneNumber: user.phoneNumber || "",
        thirdPartyMarketingConsent: user.thirdPartyMarketingConsent || "",
        firstPartyMarketingConsent: user.firstPartyMarketingConsent || "",
      });
    }
  }, [user, setFields]);

  const handleDeleteUser = async (event) => {
    try {
      await deleteWearable();
      await deleteGame(["ligafan"]);
      await deleteGame(["ligafan2"]);
      await deleteUser();
      navigate("/");
    } catch (error) {
      setAjaxError(t("formErrors.error.updateProfileGeneric"));
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHasSubmitted(true);
    const isValid = validateForm();
    if (!isValid) return;
    props.setIsLoading(true);
    try {
      await userEdit({...values}, updateUser);
      await getUserDetails(updateUser);
      setIsSuccess(true);
      setAjaxError(null);
      if (values?.igUsername === "") {
        await deleteGame(["ligafan2"]);
        await getGameStatus(updateGames);
        updateGamePrizes("ligafan2", null);
      }
    } catch (error) {
      setAjaxError(t("formErrors.error.updateProfileGeneric"));
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Container fluid>
          {/* <Row>
            <Col>
              <h1 className="page-title">{t("account.title.section.profile")}</h1>
            </Col>
          </Row> */}
          <Row className="account-fields">
            <Col>
            <Form.Group controlId="firstName">
              <FloatingLabel controlId="firstName" label={t("formLabels.label.firstName")}>
                <Form.Control
                  placeholder={t("formLabels.label.firstName")}
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={(e) =>
                    handleFieldChange("firstName", e.target.value, true)
                  }
                  isInvalid={!!errors.firstName}
                />
              </FloatingLabel>
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="lastName">
              <FloatingLabel controlId="lastName" label={t("formLabels.label.lastName")}>
                <Form.Control
                  placeholder={t("formLabels.label.lastName")}
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={(e) =>
                    handleFieldChange("lastName", e.target.value, true)
                  }
                  required
                  isInvalid={!!errors.lastName}
                />
              </FloatingLabel>
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="nickname">
              <FloatingLabel controlId="nickname" label={t("formLabels.label.nickname")}>
                <Form.Control
                  placeholder={t("formLabels.label.nickname")}
                  type="text"
                  name="nickname"
                  value={values.nickname}
                  onChange={(e) => handleFieldChange("nickname", e.target.value)}
                  isInvalid={!!errors.nickname}
                />
              </FloatingLabel>
              <Form.Control.Feedback type="invalid">
                {errors.nickname}
              </Form.Control.Feedback>
            </Form.Group>
            <InputGroup>
              <InputGroup.Text>@</InputGroup.Text>
              <Form.Group controlId="igUsername">
                <FloatingLabel controlId="igUsername" label={t("formLabels.label.igUsername")}>
                  <Form.Control
                    placeholder={t("formLabels.label.igUsername")}
                    type="text"
                    name="igUsername"
                    value={values.igUsername}
                    onChange={(e) => handleFieldChange("igUsername", e.target.value)}
                    isInvalid={!!errors.igUsername}
                  />
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">
                  {errors.igUsername}
                </Form.Control.Feedback>
              </Form.Group>
            </InputGroup>
            <Form.Group controlId="phoneNumber">
              <FloatingLabel controlId="phoneNumber" label={t("formLabels.label.phoneNumber")}>
                <Form.Control
                  placeholder={t("formLabels.label.phoneNumber")}
                  type="tel"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={(e) =>
                    handleFieldChange("phoneNumber", e.target.value)
                  }
                  isInvalid={!!errors.phoneNumber}
                />
              </FloatingLabel>
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>
            </Col>
          </Row>

          <Row className="account-checks">
            <Col>
            <Form.Group controlId="firstPartyMarketingConsent">
              <Form.Check
                name="firstPartyMarketingConsent"
                required
                label={t("formLabels.label.firstPartyMarketingConsent")}
                checked={values.firstPartyMarketingConsent}
                onChange={(e) =>
                  handleFieldChange("firstPartyMarketingConsent", e.target.checked)
                }
                isInvalid={!!errors.firstPartyMarketingConsent}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstPartyMarketingConsent}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="thirdPartyMarketingConsent">
              <Form.Check
                name="thirdPartyMarketingConsent"
                required
                label={parse(t("formLabels.label.thirdPartyMarketingConsent"))}
                checked={values.thirdPartyMarketingConsent}
                onChange={(e) =>
                  handleFieldChange("thirdPartyMarketingConsent", e.target.checked)
                }
                isInvalid={!!errors.thirdPartyMarketingConsent}
              />
              <Form.Control.Feedback type="invalid">
                {errors.thirdPartyMarketingConsent}
              </Form.Control.Feedback>
            </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button type="submit">{t("forms.update")}</Button>
              <Button onClick={handleShowModal} className="btn-secondary">
                {t("cta.delete.user")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
            <h2 className="page-title">{t("account.modal.delete.title")}</h2>
            <p className="page-subtitle">{t("account.modal.delete.text")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            {t("account.modal.delete.cta.close")}
          </Button>
          <Button variant="secondary" onClick={handleDeleteUser}>
            {t("account.modal.delete.cta.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Account;
