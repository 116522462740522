import "../styles/Info.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import parse from "html-react-parser";
import { Trans } from "react-i18next";

import campovoloMap from "../assets/LIGABUE_CAMPOVOLO_2025_viale_mappa_APP.pdf";

function Campovolo(props) {
  const { t } = props;

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="info-mirror">
              <div className="info-part">
                <div className="info-head">{t("info.infotickets.head")}</div>
                <div className="info-title">{t("info.infotickets.mail.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.mail.text"))}</div>
                <div className="info-title">{t("info.infotickets.change.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.change.text"))}</div>
                <div className="info-title">{t("info.infotickets.limits.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.limits.text"))}</div>
                <div className="info-title">{t("info.infotickets.resell.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.resell.text"))}</div>
                <div className="info-title">{t("info.infotickets.wearable.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.wearable.text"))}</div>
              </div>
              <div className="info-part">
                <div className="info-head">{t("info.infotickets.head.caserta")}</div>
                <div className="info-title">{t("info.infotickets.mail.caserta.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.mail.caserta.text"))}</div>
                <div className="info-title">{t("info.infotickets.change.caserta.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.change.caserta.text"))}</div>
                <div className="info-title">{t("info.infotickets.limits.caserta.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.limits.caserta.text"))}</div>
                <div className="info-title">{t("info.infotickets.resell.caserta.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.resell.caserta.text"))}</div>
                <div className="info-title">{t("info.infotickets.wearable.caserta.title")}</div>
                <div className="info-text">{parse(t("info.infotickets.wearable.caserta.text"))}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Campovolo;
