import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import "../styles/LigaFanGames.scss";
import { useNavigate } from "react-router-dom";
import useFormValidation from "../hooks/useFormValidation";
import { getGameStatus, deleteGame, deleteWearable, userEdit, setGameStatusPrize } from "../utils/api";
import { useUser, updateUser } from "../context/userContext";
import { getUserDetails, deleteUser } from "../utils/api";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";
import { useGame } from "../context/gameContext";
import { calcGamingPoints, calcGamingScore, findGamingScore } from "../utils/gaming";
import Cookies from 'js-cookie';

import { ReactComponent as LigaFanGamesLogo} from "../assets/LIGA_APP_LFG_logo.svg";

import { ReactComponent as IconGame} from "../assets/LIGA_APP_sfida.svg";
import { ReactComponent as IconGameBadge1} from "../assets/LIGA_APP_ligaquiz_1_stella_colore.svg";
import { ReactComponent as IconGameBadge2} from "../assets/LIGA_APP_ligaquiz_2_stelle_colore.svg";
import { ReactComponent as IconGameBadge3} from "../assets/LIGA_APP_ligaquiz_3_stelle_colore.svg";
import { ReactComponent as IconGameBadge4} from "../assets/LIGA_APP_ligaquiz_4_stelle_colore.svg";
import { ReactComponent as IconGameBadge5} from "../assets/LIGA_APP_ligaquiz_5_stelle_colore.svg";

import { ReactComponent as IconGame2} from "../assets/LIGA_APP_LFG_sfida.svg";
import { ReactComponent as IconGame2Badge1} from "../assets/LIGA_APP_unbelsouvenir_1_stella_colore_min.svg";
import { ReactComponent as IconGame2Badge2} from "../assets/LIGA_APP_unbelsouvenir_2_stelle_colore_min.svg";
import { ReactComponent as IconGame2Badge3} from "../assets/LIGA_APP_unbelsouvenir_3_stelle_colore_min.svg";
import { ReactComponent as IconGame2Badge4} from "../assets/LIGA_APP_unbelsouvenir_4_stelle_colore_min.svg";
import { ReactComponent as IconGame2Badge5} from "../assets/LIGA_APP_unbelsouvenir_5_stelle_colore_min.svg";

const LigaFanGames = (props) => {

  const { t } = props;
  const { updateUser } = useUser();
  const { user } = useUser();
  const { gamePrizes, updateGamePrizes, setGamePrizes, updateGames } = useGame();

  const navigate = useNavigate();

  useEffect(() => {
    if (props.games) {
        // console.log("________ props.games", props.games);
        // console.log("________ gamePrizes", gamePrizes);
        
        // -- calc totals
        let updatedGamePrizes = { ...gamePrizes };
        props.games.map((gameSet) => {
          const gameName = gameSet?.gameId;
          const totalPoints = calcGamingPoints(gameName, gameSet?.gamePoints);
          // console.log("________ calcGamingPoints", gameName, totalPoints);
          if (gameSet?.totalPoints === totalPoints) {
            // console.log("________ findGamingScore", gameName, gameSet?.totalPoints);
            const gamingScore = findGamingScore(gameSet?.totalPoints);
            if (gamingScore) updatedGamePrizes = { ...updatedGamePrizes, [gameName]: gamingScore };
          } else if (totalPoints >= 0) {
            const gamingScoreId = findGamingScore(totalPoints);
            const prizeName = "games." + gameName + ".prize." + gamingScoreId + ".name";
            // console.log("________ !!! setGameStatusPrize", gameName, totalPoints, t(prizeName));
            setGameStatusPrize(gameName, Cookies.get('campovolo_email'), totalPoints, t(prizeName), updateGames);
            if (gamingScoreId) updatedGamePrizes = { ...updatedGamePrizes, [gameName]: gamingScoreId };
          }
          if (updatedGamePrizes) setGamePrizes(updatedGamePrizes);
        });
    }
  }, [props.games]);
  
  //   props.setIsLoading(true);
  //   refresh();
  // });

  const handleOpenLigaFanQuiz = () => {
    navigate("/ligafan");
  };

  const handleOpenLigaFanIg = () => {
    navigate("/ligafan2");
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            {/* <h1 className="page-title title-gaming">{t("gaming.title")}</h1> */}
            <div className="ligafangames-logo">
              <LigaFanGamesLogo />
            </div>
            <p className="page-subtitle">{parse(t("gaming.subtitle"))}</p>

            <Button className="btn-grid" variant="secondary" onClick={handleOpenLigaFanIg}>
              <i className="ci ligafan2">
                {
                  (gamePrizes?.ligafan2 === "0.20" && <span style={{color: "#e30f6f"}}><IconGame2Badge1 /></span>) ||
                  (gamePrizes?.ligafan2 === "20.40" && <span style={{color: "#0a71b3"}}><IconGame2Badge2 /></span>) ||
                  (gamePrizes?.ligafan2 === "40.60" && <span style={{color: "#ec7703"}}><IconGame2Badge3 /></span>) ||
                  (gamePrizes?.ligafan2 === "60.80" && <span style={{color: "#00892e"}}><IconGame2Badge4 /></span>) ||
                  (gamePrizes?.ligafan2 === "80.100" && <span style={{color: "#e1001a"}}><IconGame2Badge5 /></span>) ||
                  <IconGame2 />
                }
              </i>
              {
                (gamePrizes?.ligafan2 === "0.20" && <span style={{color: "#e30f6f"}}>{t("menu.ligafan2.item")}</span>) ||
                (gamePrizes?.ligafan2 === "20.40" && <span style={{color: "#0a71b3"}}>{t("menu.ligafan2.item")}</span>) ||
                (gamePrizes?.ligafan2 === "40.60" && <span style={{color: "#ec7703"}}>{t("menu.ligafan2.item")}</span>) ||
                (gamePrizes?.ligafan2 === "60.80" && <span style={{color: "#00892e"}}>{t("menu.ligafan2.item")}</span>) ||
                (gamePrizes?.ligafan2 === "80.100" && <span style={{color: "#e1001a"}}>{t("menu.ligafan2.item")}</span>) ||
                t("menu.ligafan2.item")
              }
              <small>{t("menu.ligafan2.min")}</small>
            </Button> 

            <Button className="btn-grid" variant="secondary" onClick={handleOpenLigaFanQuiz}>
              <i className="ci ligafan">
                {
                  (gamePrizes?.ligafan === "0.20" && <span style={{color: "#e30f6f"}}><IconGameBadge1 /></span>) ||
                  (gamePrizes?.ligafan === "20.40" && <span style={{color: "#0a71b3"}}><IconGameBadge2 /></span>) ||
                  (gamePrizes?.ligafan === "40.60" && <span style={{color: "#ec7703"}}><IconGameBadge3 /></span>) ||
                  (gamePrizes?.ligafan === "60.80" && <span style={{color: "#00892e"}}><IconGameBadge4 /></span>) ||
                  (gamePrizes?.ligafan === "80.100" && <span style={{color: "#e1001a"}}><IconGameBadge5 /></span>) ||
                  <IconGame />
                }
              </i>
              {
                (gamePrizes?.ligafan === "0.20" && <span style={{color: "#e30f6f"}}>{t("games.ligafan.prize.0.20.name")}</span>) ||
                (gamePrizes?.ligafan === "20.40" && <span style={{color: "#0a71b3"}}>{t("games.ligafan.prize.20.40.name")}</span>) ||
                (gamePrizes?.ligafan === "40.60" && <span style={{color: "#ec7703"}}>{t("games.ligafan.prize.40.60.name")}</span>) ||
                (gamePrizes?.ligafan === "60.80" && <span style={{color: "#00892e"}}>{t("games.ligafan.prize.60.80.name")}</span>) ||
                (gamePrizes?.ligafan === "80.100" && <span style={{color: "#e1001a"}}>{t("games.ligafan.prize.80.100.name")}</span>) ||
                t("menu.ligafan.item")
              }
              <small>{gamePrizes?.ligafan ? t("menu.ligafan.item") : t("menu.ligafan.min")}</small>
            </Button>

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LigaFanGames;
