import "../styles/Header.scss";

import { matchPath, useLocation, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useUser } from '../context/userContext';
import { logoutUser } from "../utils/api";

import headingV from "../assets/LIGA_APP_testata_vert.svg";
import headingH from "../assets/LIGA_APP_testata_orizz.svg";

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { t } = props;

  const handleCloseScan = () => {
    if (props.scanner?.current) {
      props.scanner.current.stop();
      props.scanner.current.destroy();
    }
    navigate("/welcome");
  };

  const handleCloseGame = () => {
    navigate("/ligafangames");
  };

  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };
  
  return (
    <>
      <Navbar sticky="top">
        <Container fluid className={matchPath('/', location.pathname) ? 'login' : ''}>
          {!matchPath("/", location.pathname) && !matchPath("/registration", location.pathname) && !matchPath("/forgotpassword", location.pathname) && (
            <Dropdown>
              <Dropdown.Toggle>
                <i className="bi bi-person" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { navigate("/account") }}>
                  {/* {t("menu.hi")}, {user?.nickname ? user?.nickname : user?.firstName} */}
                  {t("menu.account")} {user?.nickname ? user?.nickname : user?.firstName}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => { handleLogout() }}>
                  {t("menu.logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Navbar.Brand>
            {
              matchPath("/", location.pathname) ? 
              <img loading="lazy" src={headingV} alt="Campovolo" /> : 
              <img loading="lazy" src={headingH} alt="Campovolo" onClick={() => { navigate("/welcome") }} />
            }
          </Navbar.Brand>
          {matchPath("/scan", location.pathname) || matchPath("/ligafan", location.pathname) || matchPath("/ligafan2", location.pathname) ? (
            <Button className="page-close" onClick={matchPath("/scan", location.pathname) ? handleCloseScan : handleCloseGame}>
              <i className="bi bi-x-lg" />
            </Button>
          ) : (
            <>
              {!matchPath("/", location.pathname) && !matchPath("/registration", location.pathname) && !matchPath("/forgotpassword", location.pathname) && (
                <Dropdown>
                  <Dropdown.Toggle>
                    <i className="bi bi-list" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item onClick={() => { navigate("/welcome") }}>{t("menu.welcome")}</Dropdown.Item>
                    <Dropdown.Item onClick={() => { navigate("/ligafangames") }}>{t("menu.gaming.item")}</Dropdown.Item>
                    <Dropdown.Item onClick={() => { navigate("/campovolo") }}>{t("menu.campovolo.item")}</Dropdown.Item>
                    <Dropdown.Item onClick={() => { navigate("/infotickets") }}>{t("menu.infotickets")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          )}
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
