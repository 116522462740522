import "../styles/Login.scss";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import useFormValidation from "../hooks/useFormValidation";
import { useUser } from "../context/userContext";
import { loginUser } from "../utils/api";
import { Trans } from "react-i18next";

function Login(props) {
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isSuccess, setIsSuccess] = useState(false);
  const [ajaxError, setAjaxError] = useState(null);
  const [user, setUser] = useState();

  const { t } = props;

  const navigate = useNavigate();
  const { updateUser } = useUser();

  const {
    values = {},
    errors = {},
    handleFieldChange,
    validateForm,
    setHasSubmitted,
  } = useFormValidation(
    {
      email: "",
      password: "",
    },
    t
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHasSubmitted(true);
    const isValid = validateForm();
    if (!isValid) return;

    // setIsLoading(true);
    try {
      await loginUser(
        {
          email: values.email,
          password: values.password,
        },
        updateUser
      );
      props.setLoginSuccess(true);
      navigate("/welcome");
    } catch (error) {
      // if (error?.statusCode === 412) navigate("/registration");
      setAjaxError(t("formErrors.error.loginGeneric"));
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              {/* <Form.Label>{t("formLabels.label.email")}</Form.Label> */}
              <Form.Control
                placeholder={t("formLabels.label.email")}
                type="email"
                name="email"
                value={values.email || ""}
                onChange={(e) => handleFieldChange("email", e.target.value)}
                required
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password" className="password-field">
              {/* <Form.Label>{t("formLabels.label.password")}</Form.Label> */}
              <Form.Control
                placeholder={t("formLabels.label.password")}
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password || ""}
                onChange={(e) => handleFieldChange("password", e.target.value)}
                required
                isInvalid={!!errors.password}
              />
              
              <Button
                variant="outline-secondary"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <i className="bi-eye" />
                ) : (
                  <i className="bi-eye-slash" />
                )}
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="mt-3">
              <p className="mb-0 text-center">
                <Trans
                  i18nKey="cta.forgot.password"
                  components={{
                    a: <a href="/forgotpassword" rel="noopener noreferrer" />,
                  }}
                />
              </p>
            </div>
            <Row>
              <Col>
                <Button type="submit">{t("forms.login")}</Button>
              </Col>
            </Row>
          </Form>
          {ajaxError && (<p className="text-danger page-subtitle">{ajaxError}</p>)}

          <div>
            <p className="mb-0 text-center">
              <span>Non hai ancora un account?</span>
              <Button variant="secondary" href="/registration">
                Registrati
              </Button>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
