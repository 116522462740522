import React, { createContext, useState, useContext } from 'react';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
  const [games, setGames] = useState(null);
  const [gamePrizes, setGamePrizes] = useState(null);

  const updateGames = (updatedGames) => {
    // console.log('______ updateGames', updatedGames);
    setGames(updatedGames);
  };

  const updateGamePrizes = (gameId, gameScore) => {
    // console.log('______ updateGamePrizes', gameId, gameScore);
    setGamePrizes({ ...gamePrizes, [gameId]: gameScore });
  };

  return (
    <GameContext.Provider value={{ games, updateGames, gamePrizes, updateGamePrizes, setGamePrizes }}>
      {children}
    </GameContext.Provider>
  );
};
