export const getGameSolution = (game) => {
    let gameSolution;
    if (game === 'ligafan') {
        gameSolution = window.atob("MSwzLDIsMiwyLDIsMSwzLDMsNA==");
    } else if ('ligafan2') {
        gameSolution = 100;
    }
    return gameSolution;
};

export const calcGamingPoints = (game, gamePlay) => {
    let gameSolution;
    let gameScore = 0;
    let gamePoints;

    if (game === 'ligafan') {
        gameSolution = getGameSolution(game).split(',').map((item) => {
            return parseInt(item);
        });
        if (gamePlay?.length === gameSolution.length) {
            gamePlay?.forEach((answer, index) => {
                if (parseInt(answer) === parseInt(gameSolution[index])) gameScore++;
            });
            gamePoints = (100 * gameScore) / gameSolution?.length;
        }
    }

    if (game === 'ligafan2') {
        const toArray = (...value) => {
            return value.map(item => item);
        }
        gameSolution = toArray(getGameSolution(game));
        if (gamePlay?.length === gameSolution.length) {
            gamePoints = (gamePlay[0] / gameSolution[0]) * 100;
        }
    }
    
    return gamePlay?.length === gameSolution?.length ? gamePoints : null;
};

export const findGamingScore = (gamePoints) => {
    let gameScoreId;
    if (gamePoints >= 0) {
        if (gamePoints >= 80) {
            gameScoreId = "80.100";
        } else if (gamePoints >= 60) {
            gameScoreId = "60.80";
        } else if (gamePoints >= 40) {
            gameScoreId = "40.60";
        } else if (gamePoints >= 20) {
            gameScoreId = "20.40";
        } else if (gamePoints >= 0) {
            gameScoreId = "0.20";
        } else {
            gameScoreId = null;
        }
        // console.log("_______ findGamingScore >>>>", gamePoints, gameScoreId);
    } else {
        gameScoreId = null;
    }
    return gameScoreId;
};

export const calcGamingScore = (game, gamePlay) => {
    const gamePoints = calcGamingPoints(game, gamePlay);
    const gameScoreId = findGamingScore(gamePoints);
    // console.log('_____ calcGamingScore >>>>', game, gamePlay, gamePoints, gameScoreId);
    return gameScoreId;
};