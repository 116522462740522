import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/App.scss";
import './i18n.js'

import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Routes, Route, Outlet } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

import fetchApiData from "./utils/fetchApiData";
import getApiData from "./utils/getApiData";
import Cookies from 'js-cookie';

import Loader from "./components/Loader";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Welcome from "./components/Welcome";
import Campovolo from "./components/Campovolo";
import InfoTickets from "./components/InfoTickets";
import Scan from "./components/Scan";
import Registration from "./components/Registration";
import ForgotPassword from "./components/ForgotPassword";
import Account from "./components/Account.js";
import LigaFanGames from "./components/LigaFanGames.js";
import LigaFanQuiz from "./components/LigaFanQuiz";
import LigaFanIg from "./components/LigaFanIg.js";

import { getUserDetails, checkValidToken, getGameStatus, setGameStatus, setGameStatusPrize, getWearableId, userEdit, deleteWearable, deleteGame } from "./utils/api";
import { useUser } from "./context/userContext";
import { useGame } from "./context/gameContext";

function App() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [scanResult, setScanResult] = useState();
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { user, updateUser } = useUser();
  const { games, updateGames } = useGame();

  const [showScanErrorModal, setShowScanErrorModal] = useState(false);

  const scanner = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  // const controller = new AbortController();
  // const signal = controller.signal;

  // const removeUser = () => {
    // Cookies.remove('campovolo_email', { expires: 1, secure: true });
    // Cookies.remove('campovolo_token', { expires: 1, secure: true });
    // Cookies.remove('campovolo_refreshToken', { expires: 1, secure: true });
    // console.log("______ user is removed");
    // navigate("/");
  // };

  // const checkToken = useCallback(async () => {
  //   const fetchApiUrl = `${process.env.REACT_APP_BASEURL}/auth/refreshToken`;
  //   const fetchApiHeaders = {};
  //   const fetchApiBody = {
  //     appType: process.env.REACT_APP_TYPE,
  //     email: Cookies.get('campovolo_email'),
  //     refreshToken: Cookies.get('campovolo_refreshToken'),
  //     // refreshToken: "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJsb3JlbnpvLmxhenphcmlAYWNjZW50dXJlLmNvbSIsInRlbmFudC1pZCI6IjI5NSIsImFwcC10eXBlIjoiQ09OQ0VSVCIsImp0aSI6ImU4MzllYWQzLTU2MWItNDY3OS1iMTU1LTIxNjU2NWI2YTk5NCIsImV4cCI6MTczMDgzMDAyMSwiaWF0IjoxNzMwNzQzNjIxLCJpc3MiOiJtb2JpbGVhcHAiLCJhdWQiOiJodHRwOi8vdGlja2V0aW5nLmhpZGdsb2JhbC5jb20vIn0.yL-97il1vSktuBid7iN4l86wIsSVkgVxe1RwNHWrNvugmlvew6rknjYExsCNzZLO2HwbtbxhMgq-C-oCPwV8Pg"
  //   };
  //   const response = await fetchApiData(
  //     fetchApiUrl,
  //     "POST",
  //     fetchApiHeaders,
  //     fetchApiBody
  //     // signal
  //   );
  //   if (response?.error) {
  //     console.log("______ checkToken response.error", response.error);
  //   } else {
  //     console.log("______ checkToken response", response);
  //     if (response.token && response.refreshToken) {
  //       getUserDetails(updateUser)
  //     } else {
  //       removeUser();
  //       navigate("/");
  //     }
  //   }
  // }, []);

  const checkValidTokenAsync = async () => {
    const data = await checkValidToken();
    // console.log("////// await reload", data);
    if (data && data.token) {
      getUserDetails(updateUser);
      // -- getGameStatus(updateGames);
      // -- getWearableId(setScanResult);
      if (matchPath("/", location.pathname)|| matchPath("/registration", location.pathname) || matchPath("/forgotpassword", location.pathname)) {
        navigate("/welcome");
      }
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    const fetchApis = async () => {
      await checkValidTokenAsync();
      await getGameStatus(updateGames);
      const getWearable = await getWearableId(setScanResult);
      setIsLoading(false);
      if (matchPath("/scan", location.pathname) && getWearable?.uid !== "") {
        navigate("/welcome");
        if (scanner?.current) scanner?.current?.stop();
      }
    };
  
    if (Cookies.get('campovolo_email') && Cookies.get('campovolo_refreshToken')) {
      setIsLoading(true);
      fetchApis();
    } else{
      if (!matchPath("/", location.pathname) && !matchPath("/registration", location.pathname) && !matchPath("/forgotpassword", location.pathname)) {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    // console.log("////// loginSuccess", loginSuccess);
    const fetch = async () => {
        await getUserDetails(updateUser);
        await getGameStatus(updateGames);
        await getWearableId(setScanResult);
        setIsLoading(false);
    };

    if (loginSuccess) {
      setIsLoading(true);
      fetch();
    }
  }, [loginSuccess]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout t={t} isLoading={isLoading} scanner={scanner} checkValidToken={checkValidToken} getUserDetails={getUserDetails} updateUser={updateUser} getGameStatus={getGameStatus} setGameStatus={setGameStatus} setGameStatusPrize={setGameStatusPrize} updateGames={updateGames} userEdit={userEdit} deleteWearable={deleteWearable} deleteGame={deleteGame} />}>
          <Route index element={<Login setLoginSuccess={setLoginSuccess} t={t} />} />
          <Route
            path="welcome"
            element={
              <Welcome games={games} scanResult={scanResult} setScanResult={setScanResult} showScanErrorModal={showScanErrorModal} setShowScanErrorModal={setShowScanErrorModal} t={t} />
            }
          />
          <Route path="campovolo" element={<Campovolo t={t} />} />
          <Route path="infotickets" element={<InfoTickets t={t} />} />
          <Route path="scan" element={<Scan t={t} scanner={scanner} scanResult={scanResult} setScanResult={setScanResult} setShowScanErrorModal={setShowScanErrorModal} />} />
          <Route path="registration" element={<Registration setLoginSuccess={setLoginSuccess} t={t} />}  />
          <Route path="forgotpassword" element={<ForgotPassword t={t} />}  />
          <Route path="account" element={<Account t={t} isLoading={isLoading} setIsLoading={setIsLoading} />}  />
          <Route path="ligafangames" element={<LigaFanGames t={t} isLoading={isLoading} setIsLoading={setIsLoading} games={games} />}  />
          <Route path="ligafan" element={<LigaFanQuiz t={t} isLoading={isLoading} setIsLoading={setIsLoading} />} />
          <Route path="ligafan2" element={<LigaFanIg t={t} isLoading={isLoading} setIsLoading={setIsLoading} />} />
          <Route path="*" element={<Login t={t} />} />
        </Route>
      </Routes>
    </>
  );
}

function Layout(props) {
  return (
    <>
      {props.isLoading && <Loader />}
      {<>
        {/* <button onClick={() => { props.deleteGame(["ligafan"]) }}>delete ligafan</button> */}
        {/* <button onClick={() => { props.deleteGame(["ligafan2"]) }}>delete ligafan2</button> */}
        {/* <button onClick={() => { props.deleteWearable() }}>delete wearable</button> */}
        <Header t={props.t} scanner={props.scanner}  handleLogout={props.handleLogout} />
        <main>
          {!props.isLoading && <Outlet />}
        </main>
        <Footer t={props.t} />
      </>}
    </>
  );
}

export default App;
