import "../styles/LigaFanGames.scss";

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { useGame } from "../context/gameContext";
import Cookies from 'js-cookie';
import { getGameStatus, setGameStatus, userEdit, setGameStatusPrize } from "../utils/api";
import { calcGamingPoints, calcGamingScore, findGamingScore, getGameSolution } from "../utils/gaming";
import { getUserDetails } from "../utils/api";
import { Trans } from "react-i18next";

import parse from "html-react-parser";

import { Form, Button, Container, Row, Col, InputGroup, FloatingLabel, OverlayTrigger, Popover } from "react-bootstrap";
// import ProgressBar from "react-bootstrap/ProgressBar";
import Countdown from 'react-countdown';

import { ReactComponent as IconBadge1} from "../assets/LIGA_APP_unbelsouvenir_1_stella_colore.svg";
import { ReactComponent as IconBadge2} from "../assets/LIGA_APP_unbelsouvenir_2_stelle_colore.svg";
import { ReactComponent as IconBadge3} from "../assets/LIGA_APP_unbelsouvenir_3_stelle_colore.svg";
import { ReactComponent as IconBadge4} from "../assets/LIGA_APP_unbelsouvenir_4_stelle_colore.svg";
import { ReactComponent as IconBadge5} from "../assets/LIGA_APP_unbelsouvenir_5_stelle_colore.svg";

import ligafanCover from "../assets/LIGA_APP_unbelsouvenir_polaroid.png";
import igRulesDoc from "../assets/Regolamento_Contest_Fotografico_su_Instagram.pdf";
import igPrivacyDoc from "../assets/Privacy_Policy_specifica_Contest_Fotografico.pdf";

import useFormValidation from "../hooks/useFormValidation";

function LigaFanIg(props) {
  const { t } = props;
  const navigate = useNavigate();
  const { user, updateUser } = useUser();
  const { games, updateGames, gamePrizes, updateGamePrizes } = useGame();

  const toArray = (...value) => {
    return value.map(item => item);
  }
  const gameSolution = toArray(getGameSolution('ligafan2'));
  const gameSteps = gameSolution.length;

  const [gameScore, setGameScore] = useState();
  const [gameCover, setGameCover] = useState(false);
  const [gamePlay, setGamePlay] = useState();

  const [hasAccepted, setHasAccepted] = useState(false);
  const [validated, setValidated] = useState(false);

  const [countdownCompleted, setCountdownCompleted] = useState(false);

  const updateGamePlay = (newGamePlay) => {
    setGamePlay(newGamePlay);
  };

  const handleStart = async () => {
    setGameCover(false);
    await getUserDetails(updateUser);
    // console.log("_________ handleStart hasAccepted", hasAccepted)
    if (user?.igUsername) saveBasePoints();
  };

  const handleAccept = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    // console.log("_________ handleAccept isValid", isValid)
    if (!isValid) return;
    setHasAccepted(true);
    setHasSubmitted(true);
    // handleStart();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!values.igGameUsername) {
      // -- temp fix
      handleFieldChange("igGameUsername", " ");
      handleFieldChange("igGameUsername", "");
    } else {
      const isValid = validateForm();
      // console.log("_________ handleSubmit isValid", isValid)
      if (!isValid) return;
      // setHasSubmitted(true);
      handleSave();
    }
  };

  const handleSave = () => {
    // console.log("_________ handleSave !!!!! igGameUsername", values.igGameUsername, typeof values.igGameUsername)
    if (values.igGameUsername) {
      userEdit({
        ...user,
        igUsername: values.igGameUsername
      }, updateUser);
      saveBasePoints();
    }
  };

  const saveBasePoints = () => {
    const progress = [0];
    const consents = {igGameRules: values.igGameRules, igGamePolicy: values.igGamePolicy};
    updateGamePlay(progress);
    setGameStatus('ligafan2', Cookies.get('campovolo_email'), progress, consents, updateGames);
  };

  const handleExit = () => {
    navigate("/ligafangames");
  };

  const initialFields = {
    igGameRules: false,
    igGamePolicy: false
  };
  const {
    values = {},
    errors = {},
    handleFieldChange,
    validateForm,
    setHasSubmitted,
  } = useFormValidation(hasAccepted ? { igGameUsername: "" } : initialFields, t);

  useEffect(() => {
    if (games) {
      const gameSet = games.filter(gameMatch => gameMatch.gameId === "ligafan2")[0];
      if (gameSet?.gamePoints) {
        setGameCover(gameSet.gamePoints?.length === 0 ? true : false);
        updateGamePlay(gameSet?.gamePoints);
        // -- calc totals
        let updatedGamePrizes = { ...gamePrizes };
        const totalPoints = calcGamingPoints("ligafan2", gameSet?.gamePoints);
        // console.log("________ calcGamingPoints", totalPoints);
        if (gameSet?.totalPoints === totalPoints) {
          // console.log("________ findGamingScore", gameSet?.totalPoints);
          const gamingScore = findGamingScore(gameSet?.totalPoints);
          if (gamingScore) updatedGamePrizes = { ...updatedGamePrizes, ["ligafan2"]: gamingScore };
        } else if (totalPoints >= 0) {
          const gamingScoreId = findGamingScore(totalPoints);
          const prizeName = "games.ligafan2.prize." + gamingScoreId + ".name";
          // console.log("________ !!! setGameStatusPrize", totalPoints, t(prizeName));
          setGameStatusPrize("ligafan2", Cookies.get('campovolo_email'), totalPoints, t(prizeName), updateGames);
          if (gamingScoreId) updatedGamePrizes = { ...updatedGamePrizes, ["ligafan2"]: gamingScoreId };
        }
      } else {
        setGameCover(true);
        updateGamePlay([]);
      }
    } else {
      setGameCover(true);
      updateGamePlay([]);
    }
  }, [games]);

  useEffect(() => {
    if (gamePlay?.length === gameSteps) {
      const gamingScore = calcGamingScore('ligafan2', gamePlay);
      setGameScore(gamingScore);
    }
  }, [gamePlay]);

  useEffect(() => {
    if(gameScore) updateGamePrizes("ligafan2", gameScore);
  }, [gameScore]);

  useEffect(() => {
    if(hasAccepted) handleStart();
    
  }, [hasAccepted]);

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setCountdownCompleted(true);
      return "";
    } else {
      return <div className="game-countdown">
        <div className="game-countdown-remaining">{t("games.countdown.remaining")}</div>
        <div className="game-countdown-counter">
          <div>{days}<span>{days === 1 ? t("games.countdown.day") : t("games.countdown.days")}</span></div>
          <div>{hours}<span>{hours === 1 ? t("games.countdown.hour") : t("games.countdown.hours")}</span></div>
          <div>{minutes}<span>{minutes === 1 ? t("games.countdown.minute") : t("games.countdown.minutes")}</span></div>
          <div>{seconds}<span>{seconds === 1 ? t("games.countdown.second") : t("games.countdown.seconds")}</span></div>
        </div>
      </div>;
    }
  };

  return (
    <>
      <Container fluid className="game">
        <Row>
          <Col>
            {gamePlay?.length === gameSteps ? (
              // -- game congrats
              <>
                <div className="game-mirror invert">
                  <h2>{t("games.ligafan2.title")}</h2>
                  <div className="game-hi">{t("menu.hi")} <strong>@{user.igUsername}</strong></div>
                  { 
                      (gameScore === "0.20" && 
                        <>
                          <i className="prize ligafan2" style={{color: "#e30f6f"}}><IconBadge1 /></i>
                        </>
                      ) || (gameScore === "20.40" &&
                        <>
                          <i className="prize ligafan2" style={{color: "#0a71b3"}}><IconBadge2 /></i>
                        </>
                      ) || (gameScore === "40.60" && 
                        <>
                          <i className="prize ligafan2" style={{color: "#ec7703"}}><IconBadge3 /></i>
                        </>
                      ) || (gameScore === "60.80" && 
                        <>
                          <i className="prize ligafan2" style={{color: "#00892e"}}><IconBadge4 /></i>
                        </>
                      ) || (gameScore === "80.100" && 
                        <>
                          <i className="prize ligafan2" style={{color: "#e1001a"}}><IconBadge5 /></i>
                        </>
                      )
                    }
                    {!countdownCompleted && <div className="prize-congrats">{parse(t("games.ligafan2.congrats.action"))}</div>}
                    {!countdownCompleted && <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={
                        <Popover role="tooltip">
                          <Popover.Body>
                            {t("tooltip.copied")}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div  className="copy-hashtag"onClick={() => navigator.clipboard.writeText("#unbelsouvenircontest")}>
                        #unbelsouvenircontest&nbsp;<i className="bi bi-copy"></i>
                      </div>
                    </OverlayTrigger>}
                    <div className="prize-congrats">{countdownCompleted ? parse(t("games.ligafan2.congrats.post")) : parse(t("games.ligafan2.congrats"))}</div>
                    <div className="prize-congrats-sub">{countdownCompleted ? parse(t("games.ligafan2.congrats.sub.post")) : parse(t("games.ligafan2.congrats.sub"))}</div>
                    <div className="prize-congrats-sub">{countdownCompleted ? parse(t("games.ligafan2.congrats.end.post")) : parse(t("games.ligafan2.congrats.end"))}</div>
                    {
                      <Countdown
                        date={'2025-02-01T00:00:00'}
                        renderer={countdownRenderer}
                      />
                    }
                </div>
                <Button variant="primary" onClick={handleExit}>
                  <i className="bi bi-caret-left" />
                  {t("games.cta.back")}
                </Button>
              </>
            ) : (
              // -- game cover
              gameCover ? (
                <>
                  <div className="game-mirror invert">
                    <h2 className="bigger">{t("games.ligafan2.title")}</h2>
                    <img loading="lazy" src={ligafanCover} alt="Liga Fan Game Cover" />
                    <div className="game-subtitle">{parse(t("games.ligafan2.subtitle"))}</div>
                    <div className="game-description">{parse(t("games.ligafan2.description"))}</div>
                    <Form className="acceptForm" noValidate validated={validated} onSubmit={handleAccept}>
                      <Form.Group controlId="igGameRules" className="">
                        <Form.Check
                          name="igGameRules"
                          required
                          label={
                            <Trans
                              i18nKey="formLabels.label.igGameRules"
                              components={{
                                a: <a href={igRulesDoc} target="_blank" rel="noopener noreferrer" />
                              }}
                            />
                          }
                          checked={values.igGameRules || false}
                          onChange={(e) =>
                            handleFieldChange("igGameRules", e.target.checked)
                          }
                          isInvalid={!!errors.igGameRules}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.igGameRules}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="igGamePolicy" className="">
                        <Form.Check
                          name="igGamePolicy"
                          required
                          label={
                            <Trans
                              i18nKey="formLabels.label.igGamePolicy"
                              components={{
                                a: <a href={igPrivacyDoc} target="_blank" rel="noopener noreferrer" />
                              }}
                            />
                          }
                          checked={values.igGamePolicy || false}
                          onChange={(e) =>
                            handleFieldChange("igGamePolicy", e.target.checked)
                          }
                          isInvalid={!!errors.igGamePolicy}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.igGamePolicy}
                        </Form.Control.Feedback>
                      </Form.Group>
                      
                    </Form>
                  </div>
                  <Button variant="primary" onClick={handleAccept}>
                    <i className="bi bi-caret-right" />
                    {t("games.cta.start")}
                  </Button>
                </>
              ) : (
                <>
                  <div className="game-mirror invert">
                    <h2>{t("games.ligafan2.title")}</h2>
                    <div className="game-action">{t("games.ligafan2.action")}</div>
                    <Form className="igForm" noValidate validated={validated} onSubmit={handleSubmit}>
                      <InputGroup>
                        <InputGroup.Text>@</InputGroup.Text>
                        <Form.Group controlId="igGameUsername">
                          <FloatingLabel controlId="igGameUsername" label={t("formLabels.label.igUsername")}>
                            <Form.Control
                              placeholder={t("formLabels.label.igUsername")}
                              type="text"
                              name="igGameUsername"
                              value={values.igGameUsername || ""}
                              onChange={(e) => handleFieldChange("igGameUsername", e.target.value)}
                              required
                              isInvalid={!!errors.igGameUsername}
                            />
                          </FloatingLabel>
                          <Form.Control.Feedback type="invalid">
                            {errors.igGameUsername}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </InputGroup>
                    </Form>
                  </div>
                  <Button variant="primary" onClick={handleSubmit}>
                    <i className="bi bi-chevron-right" />
                    {t("games.cta.end")}
                  </Button>
                </>
              )
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LigaFanIg;
