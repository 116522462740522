import "../styles/Footer.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as LogoRadiofreccia} from "../assets/LIGA_APP_loghiMP_radiofreccia.svg";
import { ReactComponent as LogoRTL} from "../assets/LIGA_APP_loghiMP_RTL.svg";
import { ReactComponent as LogoTelepass} from "../assets/LIGA_APP_loghiMP_telepass.svg";
import { ReactComponent as LogoTicketone} from "../assets/LIGA_APP_loghifooter_ticketone.svg";
import hidlogo from '../assets/hid-logo-rgb-300dpi-hi-res-2021-reverse.png';

import termsAndConditionsDoc from "../assets/003-Termini_e_Condizioni_WebApp_la_Notte_di_Certe_Notti.pdf";
import privacyPolicyDoc from "../assets/Privacy_Policy_Web_App_Campovolo_Caserta_2025.pdf";
// import cookiePolicyDoc from "../assets/Cookie_policy_App_Campovolo_2025.pdf";

import parse from "html-react-parser";

function Footer(props) {
  const { t } = props;

  return (
    <>
      <footer>
        <Container fluid>
          <Row>
            <Col><i id="radiofreccia" className="logo"><LogoRadiofreccia /></i></Col>
            <Col><i id="rtl" className="logo"><LogoRTL /></i></Col>
            <Col><i id="ticketone" className="logo"><LogoTicketone /></i></Col>
            <Col><i id="telepass" className="logo"><LogoTelepass /></i></Col>
          </Row>
          <Row>
            <Col>
              <small>
                {parse(t("copyright"))} - {parse(t("support"))} - <a href={termsAndConditionsDoc} target="_blank" rel="noopener noreferrer">{parse(t("terms.conditions"))}</a> - <a href={privacyPolicyDoc} target="_blank" rel="noopener noreferrer">{parse(t("privacy.policy"))}</a> - <a href="https://www.iubenda.com/privacy-policy/81650754/cookie-policy" target="_blank" rel="noopener noreferrer">{parse(t("cookie.policy"))}</a><br />
                <a className="powered-by" href="https://www.hidglobal.com" target="_blank" rel="noopener noreferrer">{parse(t("powered.by"))}<img alt="HID Global" src={hidlogo} /></a>
              </small>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
